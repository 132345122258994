const PREFIX = "/images/";

export default function Image({
  src,
  width,
  height,
  feature,
  className,
  retina,
  ...rest
}) {
  let end = "";
  const sep = src.indexOf("?") === -1 ? "?" : "&";
  if (width) {
    end += `${sep}w=${retina ? +width * 2 : width}`;
  }
  src = `/images/${src.substring(PREFIX.length)}${end}`;
  return (
    <img
      src={src}
      className={className}
      width={width}
      height={height}
      {...rest}
    />
  );
}
