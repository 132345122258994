import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import FeatureSection from "../components/FeatureSection";
import Link from "next/link";
import { useEffect, useState } from "react";
import classNames from "classnames";

export default function Forensic() {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const recalculate = () => {
      setIsScrolled(window.innerWidth < 1300 || window.scrollY > 100);
    };
    recalculate();
    window.addEventListener("scroll", recalculate);
    window.addEventListener("resize", recalculate);
    return () => {
      window.removeEventListener("scroll", recalculate);
      window.removeEventListener("resize", recalculate);
    };
  }, []);

  return (
    <Layout title="Advanced Antipiracy" navTranslucent>
      <div
        className="-z-2 absolute top-0 left-0 right-0 transition-transform duration-300 ease-in-out"
        style={{
          backgroundImage: `linear-gradient(to right, rgb(192, 132, 252), rgb(236, 72, 153), rgb(239, 68, 68))`,
          height: "85vh",
          transform: isScrolled ? "translateY(-5vh)" : "",
          minHeight: 695,
          maxHeight: 840,
        }}
      />
      <div>
        <PageHeader
          label={""}
          headline={<div className="max-w-5xl mx-auto"></div>}
        />
        <div className="lg:pt-6">
          <div className="relative max-w-xl mx-auto px-6 sm:px-6 lg:px-20 lg:max-w-screen-xl">
            <FeatureSection
              className="pb-0 sm:pb-24"
              first
              imageSide="right"
              title={
                <div className="text-white text-3xl sm:text-4xl lg:text-5xl leading-none -mt-4 sm:mt-0">
                  Eventive Launches Advanced Antipiracy Technology for Virtual
                  Cinema
                </div>
              }
              image="/images/brand/eaa-shield.png"
              imageWidth={340}
              imageHeight={300}
              imageCenter
              imageClassName="-mt-10 sm:mt-0 justify-center self-center sm:mb-0 p-12 sm:p-8 sm:p-2 transform lg:scale-150"
              hideDots
              rawImage
            />
            <FeatureSection
              imageSide="left"
              className="lg:pt-20"
              title="Maximum security for every film"
              titleFeaturedStyle
              description={
                <>
                  <p>
                    Designed to stay ahead of ever-increasing piracy threats,
                    Eventive Advanced Antipiracy is the most rigorous and
                    affordable content security program for film streaming ever
                    deployed.
                  </p>
                  <p className="mt-2">
                    The pirates are getting smarter. So artists need smarter
                    tools to protect their work.
                  </p>
                </>
              }
              image="/images/features/eaa-preroll.png"
              imageWidth={427}
              imageHeight={284}
              imageClassName={classNames(
                "self-center mb-8 mr-2 lg:mb-0 md:mx-0 ml-0 transform transition-transform ease-in-out duration-300"
              )}
              imageStyle={
                isScrolled
                  ? {}
                  : { transform: "rotate(-4deg) scale(1.3) translateY(-8vh)" }
              }
              hideDots
            />
            <FeatureSection
              imageSide="right"
              title={
                <span className="leading-tight">
                  Invisible forensic watermarking at{" "}
                  <span
                    className="text-white px-2 whitespace-no-wrap"
                    style={{ backgroundColor: "#0589ce" }}
                  >
                    no additional cost
                  </span>
                </span>
              }
              titleFeaturedStyle
              description={
                <>
                  <p>
                    Now, in addition to conventional DRM measures, every virtual
                    screening on Eventive VOD will be secured with a NAGRA
                    NexGuard invisible forensic watermark, identifying each
                    individual user and session. Meaning any unauthorized use
                    can be traced right back to the source. And a pre-roll
                    piracy warning will alert users that their unique stream can
                    be tracked.
                  </p>
                </>
              }
              image="/images/logos/nagra.png"
              imageWidth={292}
              imageHeight={107}
              imageCenter
              imageShadow
              hideDots
              moreBreathingRoom
            />
            <FeatureSection
              imageSide="left"
              titleAsImage
              title="Multi-platform Digital Rights Management (DRM) technology"
              description={
                <>
                  <p>
                    All films are secured with complete multi-platform DRM
                    (Widevine, PlayReady, and FairPlay) to prevent unauthorized
                    screen recording and downloads.
                  </p>
                  <p className="mt-2">
                    With Eventive, you can encrypt your livestreams with
                    real-time DRM, using the same studio-grade DRM available for
                    on-demand content. Our platform is widely trusted &ndash;
                    even preferred &ndash; for real-time premieres and special
                    screenings.
                  </p>
                </>
              }
              hideDots
              moreBreathingRoom
            />
            <FeatureSection
              imageSide="right"
              titleAsImage
              title="Enhanced audience access security options"
              description={
                <>
                  <p>
                    For screenings that require a higher level of protection, we
                    offer the following optional measures:
                  </p>
                  <ul className="list-disc ml-10">
                    <li className="mt-4">
                      <span className="font-medium">
                        Block software-level DRM
                      </span>{" "}
                      &ndash; Require users to view content only on native
                      browser or proprietary TV apps.
                    </li>
                    <li className="mt-4">
                      <span className="font-medium">
                        Require Two Factor Authentication
                      </span>{" "}
                      &ndash; Require users to be authenticated via SMS or phone
                      call in order to access their screening.
                    </li>
                  </ul>
                </>
              }
              hideDots
              moreBreathingRoom
            />
            <FeatureSection
              imageSide="left"
              titleAsImage
              title="Expanded admin security"
              description={
                <>
                  <p>
                    For access control to your back office account dashboard
                    (beyond the username/password combination), you may now
                    enable optional single sign-on with Active Directory / LDAP,
                    ADFS, Azure Active Directory Native, Google G Suite, OpenID
                    Connect, PingFederate, SAML, or Azure Active Directory.
                  </p>
                </>
              }
              hideDots
              moreBreathingRoom
            />
            <FeatureSection
              imageSide="right"
              titleAsImage
              title="Additional watermarking options"
              description={
                <>
                  <p className="">
                    Visible overlay watermarking is available by request for a
                    single screening or all your screenings &ndash; at no
                    additional cost. Session specific and user specific burn-in
                    watermarking are also available.
                  </p>
                </>
              }
              hideDots
              moreBreathingRoom
            />
            <div className="relative max-w-xl mx-auto px-6 sm:px-6 lg:px-8 lg:max-w-screen-xl pb-12 pt-8">
              <div className="max-w-screen-xl mx-auto text-center py-12 px-6 sm:px-12 lg:py-24 lg:px-8">
                <h2 className="text-2xl leading-7 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 max-w-lg mx-auto">
                  Are you ready for the next level of screening security?
                </h2>
                <div className="mt-8 flex justify-center">
                  <div className="inline-flex rounded-md shadow">
                    <Link href="/start">
                      <a className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-2xl leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                        Protect my films
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
