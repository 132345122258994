import Link from "next/link";

export default function PageHeader({
  label,
  labelHref = null,
  headline,
  subhead,
  isCaseStudies = false,
}) {
  return (
    <div>
      <div className="max-w-screen-xl px-6 mx-auto text-center sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto lg:max-w-none">
          <h2
            className={`text-lg font-semibold leading-6 tracking-wider text-gray-600 uppercase ${
              isCaseStudies ? "text-red-link" : "text-gray-600"
            }`}
          >
            {labelHref ? (
              <Link href={labelHref}>
                <a className="hover:text-gray-800">{label}</a>
              </Link>
            ) : (
              label
            )}
          </h2>
          <p
            className={`mt-2 text-3xl font-extrabold leading-9 text-gray-800 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none ${
              isCaseStudies ? "mb-5" : ""
            }`}
          >
            {headline}
          </p>
          {isCaseStudies ? (
            <p
              className="max-w-4xl mx-auto mt-2 text-xl leading-7 text-gray-600"
              dangerouslySetInnerHTML={{ __html: subhead }}
            />
          ) : (
            <>
              {typeof subhead === typeof {} ? (
                <p className="max-w-4xl mx-auto mt-2 text-xl leading-7 text-gray-600">
                  {subhead}
                </p>
              ) : (
                <p
                  className="max-w-4xl mx-auto mt-2 text-xl leading-7 text-gray-600"
                  dangerouslySetInnerHTML={{ __html: subhead }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
