import classNames from "classnames";
import Image from "./Image";

function Bullet({ first, icon, title, description }) {
  return (
    <li className={first ? "" : "mt-10"}>
      <div className="flex">
        {icon !== "none" ? (
          <div className="flex-shrink-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
              {/* {typeof icon === 'string' ? <Icon className="w-6 h-6"  />} */}
              <svg
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path d="M8 17a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 17m-7-5l3-3m0 0l3 3m-3-3v12"></path>
              </svg>
            </div>
          </div>
        ) : null}
        <div className={icon === "none" ? "" : "ml-4"}>
          <h5 className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </h5>
          <p className="mt-2 text-base leading-6 text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </li>
  );
}

export default function FeatureSection({
  first,
  title,
  titleAsImage,
  description,
  bullets = [],
  image,
  imageWidth,
  imageHeight,
  imageSide,
  imageGiant,
  imageCenter,
  imageShadow,
  imageClassName,
  imageStyle,
  className,
  hideDots,
  moreBreathingRoom,
  titleFeaturedStyle,
  rawImage,
}) {
  const content = (
    <div className="relative">
      {!titleAsImage ? (
        <h4
          className={classNames(
            "text-2xl leading-8 font-extrabold text-gray-900 tracking-tight",
            titleFeaturedStyle
              ? "md:text-4xl sm:leading-none"
              : "sm:text-3xl sm:leading-9"
          )}
        >
          {title}
        </h4>
      ) : null}
      <p
        className={classNames(
          "text-lg leading-7 text-gray-500",
          !titleAsImage && "mt-3"
        )}
      >
        {description}
      </p>

      {bullets?.length ? (
        <ul className="mt-10">
          {bullets.map(({ key, icon, title, description }, i) => (
            <Bullet
              key={key}
              icon={icon}
              title={title}
              description={description}
              first={i === 0}
            />
          ))}
        </ul>
      ) : null}
    </div>
  );

  const graphic = (
    <div
      class={
        rawImage
          ? `basic-image-${imageSide} flex ${imageClassName}`
          : classNames(
              imageCenter || titleAsImage || hideDots
                ? ""
                : imageSide === "right"
                ? "mb-10 lg:mb-0 mt-10"
                : "mb-10",
              "relative lg:mt-0",
              imageGiant
                ? `big-image-${imageSide}`
                : `basic-image-${imageSide}`,
              imageCenter
                ? "flex justify-center p-4 mb-10 max-w-full lg:p-0"
                : "-mx-4",
              titleAsImage ? "self-start" : "",
              imageClassName
            )
      }
      style={{ maxHeight: imageHeight, ...imageStyle }}
    >
      {!hideDots ? (
        <svg
          className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
          width="784"
          height="404"
          fill="none"
          viewBox="0 0 784 404"
          style={{ zIndex: -1 }}
        >
          <defs>
            <pattern
              id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="784"
            height="404"
            fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
          />
        </svg>
      ) : null}
      {titleAsImage ? (
        <div>
          <h4
            className={`text-2xl leading-8 font-extrabold text-gray-900 tracking-tight px-4 mb-3 md:mb-4 lg:mb-0 md:px-4 md:text-4xl md:leading-none`}
          >
            {title}
          </h4>
        </div>
      ) : typeof image === "string" ? (
        <Image
          className="relative object-contain"
          style={
            imageShadow
              ? {
                  "-webkit-filter": "drop-shadow(10px 10px 10px #ccc)",
                  filter: "drop-shadow(10px 10px 10px #ccc)",
                }
              : {}
          }
          src={image}
          width={imageWidth}
          height={imageHeight}
          feature
          alt=""
        />
      ) : (
        image
      )}
    </div>
  );

  return (
    <div
      class={classNames(
        `relative ${
          first ? "mt-3 lg:mt-12" : "mt-12 lg:mt-24"
        } flex flex-col lg:grid lg:grid-cols-2 ${
          titleAsImage ? "lg:gap-20" : "lg:gap-8"
        } lg:items-center`,
        moreBreathingRoom && "lg:mt-36",
        className
      )}
    >
      {imageSide === "right" ? (
        <>
          {content}
          {graphic}
        </>
      ) : (
        <>
          {graphic}
          {content}
        </>
      )}
    </div>
  );
}
